<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';
import { router } from '@/router';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import BreadcrumbElement from '@/components/breadcrumbs/BreadcrumbElement.vue';
import { debounce } from 'lodash';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { headingToId } from '@/helper';
import CoursePage from '@/views/courses/CoursePage.vue';
import BreadcrumbSeparator from '@/components/breadcrumbs/BreadcrumbSeparator.vue';
import CourseSectionHeader from '@/views/courses/CourseSectionHeader.vue';
import EditCoursePage from '@/views/courses/EditCoursePage.vue';
import { HSDropdown } from 'preline';
import ChapterBreadcrumbElementWithDropdown from '@/components/breadcrumbs/ChapterBreadcrumbElementWithDropdown.vue';
import SectionBreadcrumbElementWithDropdown from '@/components/breadcrumbs/SectionBreadcrumbElementWithDropdown.vue';

const courseStore = useCourseStore();
const { currentCourse, currentChapter, currentChapterSortedSections, currentChapterTitle } = storeToRefs(courseStore);
const authStore = useAuthStore();
const { userId, isAdmin: userIsAdmin } = storeToRefs(authStore);

const courseInteractionStore = useCourseInteractionStore();

const alertStore = useAlertStore();
const isEditing = ref(false);
const isLoading = ref(false);
const innerHeader = ref(null);
const sectionId = ref('');
const pageTransitionName = ref('slide-right');
const sectionTransitionName = ref('slide-right');
const appendDummyPage = ref(false);

const chapterId = ref('');
const fullWidth = ref(1024);
const sectionScrollContainer = ref(null);
const coursePageLoaded = ref(false);
const currentScrollHeight = ref(0);
const previousScrollHeight = ref(0);
const headerHeightReducedBy = ref(0);
const addingNewPage = ref(false);
const fetchCompleted = ref(false);

const showNative = ref(false);
const someDropdownOpen = ref(false);

const headerIsCollapsed = ref(false);
const headerFollowsComputedCollapseTimeout = ref(0);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  page: {
    type: Number,
    default: 0,
  },
});

const currentlyViewingPageWithIndex = ref(props.page);

const emit = defineEmits(['transition-direction']);

const currentSection = computed(() => {
  return currentChapterSortedSections.value.find((section) => section.id === sectionId.value);
});

type SectionContentItem = {
  page_index: number; // Replace with the actual type or key that corresponds to 'page_index'
  [key: string]: any; // Represents other properties in the object
};

function sectionContentItemsByPage(sectionContentItems: SectionContentItem[]): SectionContentItem[][] {
  const groupedItems: { [key: number]: SectionContentItem[] } = {};

  sectionContentItems.forEach((item) => {
    if (!groupedItems[item.page_index]) {
      groupedItems[item.page_index] = [];
    }
    groupedItems[item.page_index].push(item);
  });

  return Object.values(groupedItems);
}

const currentSectionPages = computed(() => {
  if (!currentSection.value) {
    return null;
  }
  let pages = sectionContentItemsByPage(currentSection.value.section_content_items || []);
  if (appendDummyPage.value) {
    pages.push([]);
  }
  return pages;
});

const innerHeaderHeight = computed(() => {
  return innerHeader.value ? innerHeader.value.offsetHeight : 0;
});

const currentCourseTitleShortened = computed(() => {
  return currentCourse.value.title.length > 12
    ? currentCourse.value.title.substring(0, 12) + '...'
    : currentCourse.value.title;
});

const currentChapterTitleShortened = computed(() => {
  return currentChapter.value.title.length > 10
    ? currentChapter.value.title.substring(0, 10) + '...'
    : currentChapter.value.title;
});

const currentSectionTitleShortened = computed(() => {
  if (!currentSection.value) {
    return '';
  }
  return currentSection.value.title.length > 20
    ? currentSection.value.title.substring(0, 20) + '...'
    : currentSection.value.title;
});

const currentSectionActivePageTitleShortened = computed(() => {
  // TODO implement
  return '';
});

const currentChapterSortedVisibleSections = computed(() => {
  if (isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return currentChapterSortedSections.value;
  }
  let visible = currentChapterSortedSections.value.filter((section) => section.published_at != null);
  console.log('#Visible sections: ' + visible.length);
  return visible;
});

const getWidth = () => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth: ' + screenWidth);
  if (screenWidth < 640) {
    fullWidth.value = screenWidth;
    return;
  }
  let factor = showNative.value ? 0.9 : 0.75;
  fullWidth.value = Math.round(screenWidth * factor);
};

const debounceGetWidth = debounce(getWidth, 100);

watch(showNative, () => {
  getWidth();
});

const mountingHelper = async () => {
  if (!storeLoaded.value) {
    // go one step back
    router.back();
    return;
  }

  // get editing state from local storage
  let courseId = courseStore.currentCourse.id;
  const savedState = localStorage.getItem(`course-editing-${courseId}`);
  if (savedState !== null && isOwnerOrEditorOfParentCourseOrAdmin.value) {
    isEditing.value = JSON.parse(savedState); // Restore the saved state if edit permissions
  }

  isLoading.value = false;

  await nextTick();
  HSDropdown.autoInit();
};

onBeforeMount(() => {
  sectionId.value = router.currentRoute.value.params.sectionId;
});

// Add this ref to track scroll position
const scrollPosition = ref(0);

// Add this ref to track if user has manually scrolled
const userHasScrolled = ref(false);

// Update the handleScroll function to track manual scrolling
const handleScroll = () => {
  if (sectionScrollContainer.value) {
    scrollPosition.value = sectionScrollContainer.value.scrollTop;
    userHasScrolled.value = true;
  }
};

const fetchSectionContents = async () => {
  fetchCompleted.value = false;
  if (!currentSection.value) {
    return;
  }
  courseStore
    .refetchSelectedSection(currentSection.value.index)
    .then(async () => {})
    .catch((error) => {
      alertStore.error('Fehler beim Laden der Seite: ' + error);
    })
    .finally(() => {
      fetchCompleted.value = true;
    });
  await nextTick();
};

onMounted(async () => {
  isLoading.value = true;
  await nextTick(async () => {
    await mountingHelper();
  });

  getWidth();
  window.addEventListener('resize', debounceGetWidth);

  // Add scroll event listener
  if (sectionScrollContainer.value) {
    sectionScrollContainer.value.addEventListener('scroll', handleScroll);
  }

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  emit('transition-direction', ''); // reset router transition

  setTimeout(async () => {
    await fetchSectionContents(); // replaces case preview with full case details
  }, 200); // deferring this is a bit of a dirty hack
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceGetWidth);

  // Remove scroll event listener
  if (sectionScrollContainer.value) {
    sectionScrollContainer.value.removeEventListener('scroll', handleScroll);
  }
});

const pageId = () => {
  return 'chapter-' + courseStore.currentChapterId;
};

const pageHeading = () => {
  return courseStore.currentChapterTitle;
};

const hashtagedId = () => {
  return '#' + pageId();
};

const storeLoaded = computed(() => {
  return currentCourse.value !== null && currentChapter.value !== null && currentChapterSortedSections.value !== null;
});

const isOwnerOrEditorOfParentCourseOrAdmin = computed(() => {
  return (
    currentCourse.value &&
    (courseStore.isOwnerOfCurrentCourse(userId.value) ||
      courseStore.isEditorOfCurrentCourse(userId.value) ||
      userIsAdmin.value)
  );
});

function scrollToSection(sectionTitle: string) {
  // TODO this needs to become scrollToPage !
  console.log('Scrolling to section: ' + sectionTitle);
  let id = headingToId(sectionTitle);
  const element = document.getElementById(id);
  console.log('Element: ' + element);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const goToChapter = async (chapterIndex: number) => {
  await router.isReady();
  await router.push('/chapter/' + courseStore.currentCourse.chapters[chapterIndex].id);
};

const goToSection = async (index: number) => {
  if (index === currentSection.value.index) {
    return;
  }
  await router.isReady();
  // Determine the transition direction
  const direction = index < currentSection.value.index ? 'slide-right' : 'slide-left';
  // Emit the direction to the parent
  emit('transition-direction', direction);
  await router.push('/section/' + courseStore.currentChapterSortedSections[index].id);
};

const goToPage = async (newPageIndex: number) => {
  if (newPageIndex < 0 || newPageIndex >= currentSectionPages.value.length) {
    return;
  }
  if (newPageIndex === currentlyViewingPageWithIndex.value) {
    return;
  }
  currentlyViewingPageWithIndex.value = newPageIndex;
};

watch(
  () => currentlyViewingPageWithIndex.value,
  (newIndex, oldIndex) => {
    pageTransitionName.value = newIndex > oldIndex ? 'slide-left' : 'slide-right';
  },
);

const toggleIsEditing = (isEditingNow: boolean) => {
  if (!isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return;
  }
  if (!storeLoaded.value) {
    return;
  }
  isEditing.value = isEditingNow;
  localStorage.setItem(`course-editing-${courseStore.currentCourse.id}`, JSON.stringify(isEditingNow));
};

const addPage = async () => {
  if (!isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return;
  }
  if (!storeLoaded.value) {
    return;
  }
  addingNewPage.value = true;
  appendDummyPage.value = true;
  setTimeout(() => {
    const lastPage = currentSectionPages.value.length - 1;
    goToPage(lastPage);
  }, 200);
};

// Update handleCoursePageLoaded function
const handleCoursePageLoaded = async (pageIndex: number, loaded: boolean, hasContent: boolean) => {
  if (!loaded || !sectionScrollContainer.value) return;

  if (pageIndex === currentlyViewingPageWithIndex.value) {
    coursePageLoaded.value = true;
  }

  addingNewPage.value = !hasContent;
  console.log('newPage: ' + addingNewPage.value);

  if (!hasContent) {
    // new page added
    setTimeout(() => {
      scrollToBottom();
    }, 700); // delay: let slide animation finish first
    // new section added & load complete => scroll to bottom
    return;
  } else if (!!currentSectionPages.value && pageIndex === currentSectionPages.value.length - 1) {
    addingNewPage.value = false; // last page has content, so adding new page is finished
    appendDummyPage.value = false;
    return;
  }

  // Only scroll to top if this is the current page and user hasn't manually scrolled
  if (pageIndex === currentlyViewingPageWithIndex.value && !userHasScrolled.value) {
    await nextTick();
    sectionScrollContainer.value.scrollTop = 0;
  }
};

watch(
  () => currentSectionPages.value?.length,
  (newLength, oldLength) => {
    if (newLength > oldLength && !addingNewPage.value) {
      appendDummyPage.value = false;
    }
  },
);

const scrollToBottom = () => {
  if (!sectionScrollContainer.value) return;
  console.log('scrolling to bottom');
  sectionScrollContainer.value.scroll({
    top: sectionScrollContainer.value.scrollHeight,
    behavior: 'smooth',
  });
};

const courseSectionHeader = ref(null);

const minHeaderHeight = computed(() => {
  if (!courseSectionHeader.value?.minHeight) return 64;
  return courseSectionHeader.value.minHeight;
});

const collapsableHeaderHeight = computed(() => {
  if (!courseSectionHeader.value?.fullHeight) return null;
  return courseSectionHeader.value.fullHeight - minHeaderHeight.value;
});

const computedHeaderHeight = computed(() => {
  if (!courseSectionHeader.value?.fullHeight) return null;
  if (!collapsableHeaderHeight.value) return null;

  const fullHeight = courseSectionHeader.value.fullHeight;

  // Start collapsing only after scrolling past the difference
  const collapsibleHeight = fullHeight - minHeaderHeight.value;
  const remainingHeight =
    minHeaderHeight.value + Math.max(0, collapsableHeaderHeight.value - headerHeightReducedBy.value);

  return remainingHeight;
});

watch(computedHeaderHeight, (newHeight) => {});

const computedStyleScrollContainer = computed(() => {
  return {
    flex: '1 1 auto',
    overflowY: 'auto',
    minHeight: 0,
  };
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(100vh - ${props.outerHeaderHeight}px)`,
  };
});

const screenIsMdOrLarger = computed(() => {
  return window.innerWidth > 768;
});

const lastScrollAmount = ref(0);

const handleScrollBy = async (distance: number) => {
  let scrollPositionBefore = sectionScrollContainer.value?.scrollTop;
  sectionScrollContainer.value?.scrollBy(0, distance);
  sectionScrollContainer.value?.addEventListener(
    'scrollend',
    () => {
      let scrollPositionAfter = sectionScrollContainer.value?.scrollTop;
      lastScrollAmount.value = scrollPositionAfter - scrollPositionBefore;
    },
    { once: true },
  );
};

const handleUndoLastScroll = () => {
  if (lastScrollAmount.value) {
    sectionScrollContainer.value?.scrollBy(0, -lastScrollAmount.value);
    lastScrollAmount.value = 0;
  }
};

const headerIsInView = computed(() => {
  if (!courseSectionHeader.value?.fullHeight) return true;
  return scrollPosition.value + minHeaderHeight.value < courseSectionHeader.value.fullHeight;
});

// Reset userHasScrolled when changing pages
watch(
  () => currentlyViewingPageWithIndex.value,
  () => {
    userHasScrolled.value = false;
  },
);
</script>

<template>
  <transition :name="sectionTransitionName" :mode="'out-in'">
    <div class="flex flex-col min-w-full overflow-hidden" :style="computedStyleMain">
      <header class="flex min-w-full overflow-visible">
        <!-- Navigation -->
        <h2
          ref="innerHeader"
          :class="{
            'overflow-visible': someDropdownOpen,
            'overflow-y-visible overflow-x-scroll': !someDropdownOpen,
          }"
          class="w-full sticky top-0 z-40 text-base text-gray-800 dark:text-gray-200 bg-white dark:bg-neutral-900 pb-4 justify-center flex"
        >
          <div class="w-full mx-auto px-2 md:px-6 lg:px-8 inline-flex justify-between">
            <ol class="inline-flex overflow-visible items-center whitespace-nowrap w-full">
              <!-- level 1 breadcrump: all courses -->
              <BreadcrumbElement class="hidden md:inline-flex" label="Kurse" to="/courses" />
              <!-- level 2 breadcrump: current course -->
              <BreadcrumbElement
                class="hidden md:inline-flex"
                :label="currentCourse.title"
                :to="'/course/' + currentCourse.id"
              />
              <BreadcrumbElement
                class="inline-flex md:hidden"
                :label="currentCourseTitleShortened"
                :to="'/course/' + currentCourse.id"
              />
              <!-- level 3 breadcump: current chapter -->
              <ChapterBreadcrumbElementWithDropdown
                :current-chapter="currentChapter"
                :current-chapter-title="currentChapterTitle"
                :current-chapter-title-shortened="currentChapterTitleShortened"
                :chapters="currentCourse.chapters"
                id-prefix="course-section"
                @dropdownOpen="someDropdownOpen = $event"
                @goToChapter="goToChapter"
              />
              <BreadcrumbSeparator />

              <!-- level 4 breadcump: current section -->
              <SectionBreadcrumbElementWithDropdown
                :current-section="currentSection"
                :current-section-title="currentSection.title"
                :current-section-title-shortened="currentSectionTitleShortened"
                :sections="currentChapterSortedVisibleSections"
                id-prefix="course-section"
                @goToSection="goToSection"
                @dropdownOpen="someDropdownOpen = $event"
              />
            </ol>
          </div>
        </h2>
      </header>

      <main
        ref="sectionScrollContainer"
        v-if="storeLoaded"
        :id="pageId()"
        class="flex flex-col min-w-full pb-4 scroll-smooth"
        :style="computedStyleScrollContainer"
      >
        <div
          class="flex z-30 mx-auto transition-[width] duration-500"
          :style="{
            position: 'static',
            width: `${fullWidth}px`,
          }"
        >
          <CourseSectionHeader
            ref="courseSectionHeader"
            :is-in-view="true"
            :chapter="currentChapter"
            :section="currentSection"
            :numVisibleSections="currentChapterSortedVisibleSections.length"
            :allow-editing="isEditing"
            :allow-toggle-editing="isOwnerOrEditorOfParentCourseOrAdmin"
            @onToggleIsEditing="toggleIsEditing"
            @onAddPage="addPage"
            @scrollTo="(sectionTitle) => scrollToSection(sectionTitle)"
            @toPreviousSection="goToSection(currentSection.index - 1)"
            @toNextSection="goToSection(currentSection.index + 1)"
            @toPreviousPage="goToPage(currentlyViewingPageWithIndex - 1)"
            @toNextPage="goToPage(currentlyViewingPageWithIndex + 1)"
            :pageIndex="currentlyViewingPageWithIndex"
            :numPages="currentSectionPages?.length"
          />
        </div>
        <div
          v-show="!headerIsInView"
          class="flex z-30 mx-auto duration-500 ease-in-out"
          :style="{
            position: 'fixed',
            width: `100%`,
          }"
        >
          <CourseSectionHeader
            :is-in-view="false"
            :chapter="currentChapter"
            :section="currentSection"
            :numVisibleSections="currentChapterSortedVisibleSections.length"
            :allow-editing="isEditing"
            :allow-toggle-editing="isOwnerOrEditorOfParentCourseOrAdmin"
            @onToggleIsEditing="toggleIsEditing"
            @onAddPage="addPage"
            @scrollTo="(sectionTitle) => scrollToSection(sectionTitle)"
            @toPreviousSection="goToSection(currentSection.index - 1)"
            @toNextSection="goToSection(currentSection.index + 1)"
            @toPreviousPage="goToPage(currentlyViewingPageWithIndex - 1)"
            @toNextPage="goToPage(currentlyViewingPageWithIndex + 1)"
            :pageIndex="currentlyViewingPageWithIndex"
            :numPages="currentSectionPages?.length"
          />
        </div>

        <div
          v-for="(page, pageIndex) in currentSectionPages"
          :key="`page-${pageIndex}-${page[0]?.id || ''}`"
          class="relative"
        >
          <transition :name="pageTransitionName" :mode="'out-in'">
            <div v-show="pageIndex === currentlyViewingPageWithIndex" class="pt-4">
              <EditCoursePage
                v-if="isEditing"
                :pageContents="page"
                :pageIndex="currentlyViewingPageWithIndex"
                :fetchingSectionContentsCompleted="fetchCompleted"
                :sectionIndex="currentSection.index"
                :sectionId="currentSection.id"
                :chapterId="chapterId"
                :chapterIndex="currentChapter.index"
                :show-native="showNative"
                :full-width="fullWidth"
                :outer-header-height="props.outerHeaderHeight + innerHeaderHeight"
                :current-scroll-height="currentScrollHeight"
                @scrollToBottom="scrollToBottom"
                @scrollTo="
                  (scrollHeight) => {
                    console.log('scrolling to ', scrollHeight);
                    sectionScrollContainer.scroll({
                      top: scrollHeight,
                      behavior: 'smooth',
                    });
                  }
                "
                @pageLoaded="handleCoursePageLoaded"
                @refetchSectionContents="fetchSectionContents"
              />
              <CoursePage
                v-else
                :pageContents="page"
                :pageIndex="pageIndex"
                :fetchingSectionContentsCompleted="fetchCompleted"
                :sectionIndex="currentSection.index"
                :sectionId="currentSection.id"
                :chapterId="chapterId"
                :chapterIndex="currentChapter.index"
                :show-native="showNative"
                :full-width="fullWidth"
                :outer-header-height="props.outerHeaderHeight + innerHeaderHeight"
                @showNativeToggled="showNative = $event"
                @scrollBy="handleScrollBy"
                @undoLastScroll="handleUndoLastScroll"
                @pageLoaded="handleCoursePageLoaded"
              />
            </div>
          </transition>
        </div>
      </main>
      <div v-else class="min-w-full mx-auto flex">
        <div class="pt-28 w-full flex mx-auto justify-center items-center h-full">
          <LoadingSpinnerLarge />
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
/* Slide Left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  position: absolute;
  /* Ensure no overlap */
  top: 4;
  left: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
}

.slide-left-enter-from {
  transform: translateX(105%);
  /* Start slightly further out for a gap */
  opacity: 0.2;
}

.slide-left-enter-to {
  transform: translateX(0%);
  /* Slide into position */
  opacity: 1;
}

.slide-left-leave-from {
  transform: translateX(0%);
  /* Start at original position */
  opacity: 1;
}

.slide-left-leave-to {
  transform: translateX(-105%);
  /* Slide slightly further out for a gap */
  opacity: 0.2;
}

/* Slide Right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  position: absolute;
  /* Ensure no overlap */
  top: 4;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-right-enter-from {
  transform: translateX(-105%);
  /* Start slightly further out for a gap */
  opacity: 0.2;
}

.slide-right-enter-to {
  transform: translateX(0%);
  /* Slide into position */
  opacity: 1;
}

.slide-right-leave-from {
  transform: translateX(0%);
  /* Start at original position */
  opacity: 1;
}

.slide-right-leave-to {
  transform: translateX(105%);
  /* Slide slightly further out for a gap */
  opacity: 0.2;
}
</style>
